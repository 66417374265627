import * as React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Accordion from '../components/Accordion/Accordion';

export const TermsFaq = [
  {
    question: 'Introduction',
    answer: (
      <p>
        These Terms of Use (“Terms” or “Agreement”) constitute a binding
        agreement between you (“you” or “your”) and Aeldra, Inc. (“Aeldra”) and
        contain the terms and conditions governing your access to and use of:
        (1) our website at Aeldra.com and all of our other websites to which
        these Terms are posted (collectively, the “Website”); (2) our web or
        mobile applications (collectively, the “Application”); and (3) any
        products, services, and applications made available through the Website
        or the Application (together with the Website and the Application, the
        “Services”). “You” and “your” mean the person who uses or accesses the
        Services. “We,” “us,” and “our” mean Aeldra and its successors,
        affiliates, and assignees.
      </p>
    ),
  },
  {
    question: 'Acceptance of Agreement',
    answer: (
      <p>
        By accessing and/or using the Services, you acknowledge that you have
        read, understood, and agree to be bound by the following terms of
        conditions set forth in this Agreement. If you do not agree with these
        terms and conditions, you may not access or use the Services. By using
        or accessing the Services or by clicking to agree to these Terms when
        that option is made available to you, you represent that you have read
        and understand these Terms.
      </p>
    ),
  },
  {
    question: 'Privacy Policy',
    answer: (
      <p>
        Your use of and access to the Services are subject at all times to our
        privacy policy. By using or accessing the Services or by clicking to
        agree to the privacy policy when that option is made available to you,
        you represent that you have read and understand our Privacy Policy and
        you agree to be bound by our Privacy Policy.
      </p>
    ),
  },
  {
    question: 'Use of Aeldra Services',
    answer: (
      <p>
        You may not use the Services in any manner that may impair, overburden,
        damage, disable or otherwise compromise (i) Aeldra&apos;s Services; (ii)
        any other party&apos;s use and enjoyment of the Services; or (iii) the
        services and products of any third parties including, without
        limitation, any device through which you access the Application (the
        “Authorized Device”). You agree to comply with all applicable laws and
        regulations governing the downloading, installation and/or use of the
        Application, including, without limitation, any usage rules set forth in
        the online application store terms of service.
      </p>
    ),
  },
  {
    question: 'Updates to Services',
    answer: (
      <>
        <p>
          From time to time, Aeldra may automatically check the version of the
          Application installed on the Authorized Device and, if applicable,
          provide updates for the Aeldra Application (“Updates”). Updates may
          contain, without limitation, bug fixes, patches, enhanced
          functionality, plug-ins and new versions of the Aeldra Application. By
          installing the Application, you authorize the automatic download and
          installation of Updates and agree to download and install Updates
          manually if necessary. Your use of the Application and Updates will be
          governed by this Agreement (as amended by any terms and conditions
          that may be provided with Updates). Aeldra reserves the right to
          temporarily disable or permanently discontinue any and all
          functionality of the Application at any time without notice and with
          no liability to you.
        </p>
        <p>
          IF YOU DO NOT AGREE TO THESE TERMS AND OUR PRIVACY POLICY, YOU MAY NOT
          USE OR ACCESS THE SERVICES. PLEASE READ THESE TERMS AND OUR PRIVACY
          POLICY CAREFULLY BEFORE USING OR ACCESSING THE SERVICES. THESE TERMS
          CONTAIN A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT REQUIRES
          THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS ACTIONS. YOU SHOULD ALSO RETAIN A
          COPY OF THIS AGREEMENT FOR YOUR RECORDS. ALL CAPITALIZED TERMS SHALL
          HAVE THE MEANING SET FORTH IN THIS AGREEMENT.
        </p>
      </>
    ),
  },
  {
    question: 'Update to Agreement',
    answer: (
      <p>
        Aeldra reserves the right, in its sole discretion, at any time to
        modify, augment, limit, suspend, discontinue or terminate any or all of
        the Services without advance notice. All modifications and additions to
        the Services shall be governed by this Agreement, unless otherwise
        expressly stated by Aeldra in writing. Aeldra may, from time to time,
        modify the Agreement. Please check this Agreement periodically for
        changes. Your continued use of the Services after the changes become
        effective constitutes your binding acceptance of such changes. In the
        event that a change to this Agreement materially modifies your rights or
        obligations, we will make an effort to notify you of the change, such as
        by sending you an email to the address we have on file for you, or
        presenting a pop-up window or other notification to you through the
        Services when you log in, and we may require that you accept the
        modified Agreement in order to continue to use the Services. Immaterial
        modifications are effective upon publication, and material changes will
        be effective upon the earlier of (a) continued use of the Services with
        actual knowledge of the modification, or (b) thirty (30) days following
        the change. For the avoidance of doubt, disputes arising hereunder will
        be resolved in accordance with the Agreement in effect that the time the
        dispute arose. You can determine when this Agreement was last revised by
        referring to the “LAST UPDATED” legend at the top of then-current
        version of this Agreement.
      </p>
    ),
  },
  {
    question: 'Aeldra Services',
    answer: (
      <p>
        Aeldra Financial Inc. provides a range of financial products and
        services to meet customers&apos; banking* needs in the U.S. Our services
        include Checking &amp; Savings Accounts*, Certificates of Deposit,
        Credit Cards, Loans, Insurance and Investment solutions.
      </p>
    ),
  },
  {
    question: 'Eligibility',
    answer: (
      <p>
        In order to use the Services, you must: (a) accept and agree to these
        Terms and our Privacy Policy (b) register with us on the Website or the
        Application; (c) be a Resident of the United States or a Citizen and
        Resident of India, (d) be at least 18 years old; (e) provide all
        information requested by us, such as your name, email address, mobile
        device number, online credentials for your Bank Account*, and such other
        information as we may request from time to time (collectively, “User
        Information”); (f) Indian residents will be restricted from debit card
        usage until the customer is physically in the U.S. Customers will have
        to follow applicable Aeldra procedures which will include providing
        documentation that establishes physical presence in the U.S. and by
        contacting Aeldra Concierge to remove the usage restriction; and (g)
        only use these Services in compliance with any and all applicable laws
        and regulations. You represent and warrant that all User Information you
        provide us from time to time is truthful, accurate, current, and
        complete, and you agree not to misrepresent your identity or your User
        Information. You agree to promptly notify us of changes to your User
        Information by updating your Aeldra account* on the Website or the
        Application. If we approve your registration, you will be authorized to
        use the Services, subject to these Terms.
      </p>
    ),
  },
  {
    question: 'Accounts*',
    answer: (
      <>
        <p>
          Account* Creation. In order to use the Services, you must create an
          account* (an “Account*”) with Aeldra. You agree that the information
          you provide to Aeldra on registration and at all other times, will be
          true, accurate, current, and complete, and that you will keep this
          information accurate and up-to-date at all times. We also request that
          you create a password for your Account*. You agree not to disclose
          your password to anyone else, and you also agree you will be solely
          responsible for any activities or actions take under your Account*,
          whether or not authorized by you. Please notify us immediately of any
          unauthorized use of your password or Account*. We are not liable for
          any loss or damage from your failure to comply with these
          requirements.
        </p>
        <p>
          Identity Verification. You hereby authorize Aeldra, directly or
          through third parties, to make any inquiries we consider necessary to
          validate your identity and/or authenticate your identity and Account*
          information. This may include asking you for further information
          and/or documentation about your identity, or requiring you to take
          steps to confirm ownership of your email address, wireless/cellular
          telephone number or financial instruments, and verifying your
          information against third party databases or through other sources.
        </p>
        <p>
          Push Notifications and Text Messages. By agreeing to this Agreement,
          you agree to receive push notifications from us. You can opt out of
          receiving push notifications through your device settings. Please note
          that opting out of receiving push notifications may impact your use of
          the Services. By providing us with a telephone number for a cellular
          phone or other wireless device, you agree to receive autodialed and
          pre-recorded, non-marketing, service-related text messages from or on
          behalf of Aeldra at the phone number provided. You further consent to
          receiving autodialed and pre-recorded text messages from or on behalf
          of Aeldra at the number provided for marketing or promotional
          purposes. You understand that consent to marketing-related messages is
          not a condition of using the Services. To stop receiving
          marketing-related messages, you may reply STOP to any
          marketing-related text message you receive from Aeldra. After you text
          “STOP” to us, we will send you a text to confirm that you have been
          unsubscribed. After this, you will no longer receive text messages
          from us. Standard message and data rates may apply to both
          non-marketing and marketing-related messages.
        </p>
        <p>
          Limitations on User Accounts*. You may not create more than one (1)
          Aeldra Account*. Each unique mobile device may not be associated with
          more than two (2) user Accounts*. Users who attempt to associate an
          excessive number of mobile devices with a single user Account* may be
          deemed to have violated this Agreement to the extent they are deemed
          by Aeldra to have abused the Services, and may be subject to Account*
          suspension or closure. You may only create an account* for your own
          personal, non-commercial use and not on behalf of or for the benefit
          of any third party. If you create an account* with Aeldra, you are
          responsible for maintaining the security of your account*, and you are
          fully responsible for all activities that occur under the account* and
          any other actions taken in connection with the Services. You may not
          share your account* information with any other person or entity, and
          Aeldra is not liable for any fraud or losses caused by the sharing of
          account* information or log-in credentials. You must immediately
          notify Aeldra of any unauthorized uses of your account* or any other
          breaches of security. Aeldra will not be liable for any acts or
          omissions by you, including any damages of any kind incurred as a
          result of such acts or omissions.
        </p>
        <p>
          Terminating an Account* by Aeldra. Without limiting other remedies,
          Aeldra may immediately terminate or suspend your access to and/or use
          of the Aeldra Services and remove, subject to any regulatory retention
          requirements, any material from the Services or our servers, in the
          event that you breach this Agreement. Notwithstanding the foregoing,
          we also reserve the right to terminate, limit or suspend your access
          to or use of the Aeldra Services at any time and for any reason or no
          reason, including: (i) where we determine in our sole discretion that
          such action is reasonable in order to comply with legal requirements
          or to protect the rights or interests of Aeldra or any third party; or
          (ii) in connection with any general discontinuation of the Services.
          We also reserve the right to modify the Services at any time without
          notice to you. We will have no liability whatsoever on account* of any
          change to the Services or any suspension or revocation of your access
          to or use of the Services.
        </p>
        <p>
          Terminating an Account* by You. You may close your Aeldra account* and
          terminate your relationship with us without cost, but you will remain
          liable for all obligations related to your Aeldra account* even after
          the account* is closed. Any incomplete transactions or transfers must
          be completed or canceled, and you must transfer any money from your
          Aeldra account* before closing it. If you voluntarily close your
          account*, or allow your account* to lapse, you may be unable to
          reactivate that account*. We may permanently or temporarily terminate
          or suspend your Aeldra account* or any access to the Services without
          notice and liability for any reason, including if in our sole
          discretion you violate any provision of these Terms, or for no reason.
          If we have previously terminated your account* for any reason, we
          further reserve the right, in our sole discretion, to prohibit you
          from reactivating such account* or creating a new account*. All
          provisions of these Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <p> In certain cases, closing an account* may be delayed including:</p>
        <ul>
          <li>
            {' '}
            If you have a pending transaction or an open dispute or claim.
          </li>
          <li> If you owe amounts to us.</li>
          <li>
            {' '}
            If your Aeldra account* is subject to a hold, limitation or reserve.
          </li>
          <li> If there is a pending investigation.</li>
        </ul>
        <p>
          Effect of termination. Upon termination of this Agreement: you
          understand and acknowledge that we will have no further obligation to
          provide or allow access to your Account* or the Services. Upon
          termination, all licenses and other rights granted to you by this
          Agreement will immediately cease. Aeldra is not liable to you or any
          third party for termination of the Services or termination of your use
          of the Services. UPON ANY TERMINATION OR SUSPENSION, ANY INFORMATION
          THAT YOU HAVE SUBMITTED, UPLOADED OR OTHERWISE MADE AVAILABLE ON, TO
          OR THROUGH THE AELDRA SERVICES OR THAT WHICH IS RELATED TO YOUR
          ACCOUNT* MAY NO LONGER BE ACCESSED BY YOU. Furthermore, except as may
          be required by applicable law or as provided in our Privacy Policy,
          Aeldra will have no obligation to store or maintain (or delete or
          destroy) any information stored in our database or to forward any
          information to you or any third party.
        </p>
      </>
    ),
  },
  {
    question: 'Fees and Limits',
    answer: (
      <>
        <p>
          We may, at our discretion, impose limits on account* activity for the
          purposes of protecting Aeldra and you from fraud or other risks. In
          addition, we may at our discretion update our fee structure, subject
          to appropriate advance notice to you.
        </p>
        <p>
          Fees and limits may change from time to time in our sole discretion.
          Your continued use of the Services after any change in fees becomes
          effective constitutes your acceptance of the updated fees.
        </p>
      </>
    ),
  },
  {
    question: 'Third Parties',
    answer: (
      <p>
        The Services, including our websites, may contain links to third-party
        websites. The linked sites are not under our control, and we are not
        responsible for the contents of any linked site. We provide these links
        as a convenience only, and a link does not imply our endorsement of,
        sponsorship of, or affiliation with the linked site. You should review
        the third party before proceeding with any transaction with any of these
        third parties&apos; services or websites. In addition, Aeldra may rely
        on third parties to provide a range of technology and banking* services.
        You agree that Aeldra may share your information with these third
        parties, subject to the limitations outlined in the Privacy Policy.
      </p>
    ),
  },
  {
    question: 'Prohibited Conduct',
    answer: (
      <>
        <p>
          Aeldra strictly prohibits illicit activity. Important notice: Aeldra
          reserves the right to suspend an account* at any time, and without
          notice, if the account* has been flagged as suspicious or fraudulent
          for any reason by our security team. Suspended accounts will require
          investigation. the following and may close your account* upon
          identifying any of the following actions:
        </p>
        <ol type="number">
          <li>Breach this Agreement or any Aeldra policy</li>
          <li>
            {' '}
            Access or use any part of the Services for any non-personal,
            commercial purpose;
          </li>
          <li>
            {' '}
            Access or use the Services for any illegal purpose or violate any
            law, statute, ordinance, or regulation;
          </li>
          <li>
            {' '}
            Attempt to establish Account* using someone else’s identity or
            unauthorized access to any other user’s Account*;
          </li>
          <li>
            {' '}
            Modify or attempt to modify or in any way tamper with, circumvent,
            disable, damage or otherwise interfere with the Services;
          </li>
          <li>
            {' '}
            Access or use the Services in a way that may infringe upon the
            intellectual property or other rights of any third party, including,
            without limitation, trademark, copyright, privacy, or publicity
            rights;
          </li>
          <li> Provide false, inaccurate or misleading information;</li>
          <li>
            {' '}
            Copy, distribute, transfer, sell or license all or part of the
            Services;
          </li>
          <li>
            {' '}
            Intentionally interfere with or damage operation of the Services or
            any user’s enjoyment of it, by any means, including uploading or
            otherwise disseminating viruses, adware, spyware, worms, or other
            malicious code;
          </li>
          <li>
            {' '}
            Take any action to circumvent, compromise or defeat any security
            measures implemented in the Services;
          </li>
          <li>
            {' '}
            Use the Services to access, copy, transfer, retransmit or transcode
            information, Aeldra’s logos, marks, names or designs or any other
            content in violation of any law or third party rights;
          </li>
          <li>
            {' '}
            Remove, obscure, or alter Aeldra’s (or any third party’s) copyright
            notices, trademarks, or other proprietary rights notices affixed to
            or contained within or accessed through the Services;
          </li>
          <li>
            {' '}
            Act in a manner that is defamatory, trade libelous, threatening or
            harassing; or
          </li>
          <li>
            {' '}
            Use the Services in a manner that results in or may result in:
            <ol type="decimal">
              <li> complaints;</li>
              <li>disputes; claims, reversals, chargebacks, or</li>
              <li>
                fees, fines, penalties or other liability or losses to Aeldra,
                other users, third parties or you.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Except as otherwise expressly stated in these Terms or required by
          applicable law, we are not responsible for any losses arising out of
          the loss or theft of your User Information, username, password,
          computer, or mobile device or from unauthorized or fraudulent
          transactions associated with your Aeldra account*, your bank*
          account*, credit card or debit card^. If you suspect or become aware
          of any unauthorized activity or access to your username, password,
          computer or mobile device, you must contact us immediately at
          compliance@aeldra.com.
        </p>
      </>
    ),
  },
  {
    question: 'Account* Review',
    answer: (
      <>
        <p>
          We review account* and transaction activity at various times,
          including when bank* transfers are initiated. This review checks for,
          among other things, suspicious or illegal activity, and whether your
          account* activity and the activity of users with whom you’ve
          transacted comply with this agreement. In connection with our review
          process, you may be required to provide us with additional information
          and/or documentation to verify your identity or the purposes of the
          transaction(s). We may limit your account* and your access to funds in
          your account* until we complete our review.
        </p>

        <p>Reviews may result in:</p>
        <li>Delayed, blocked or cancelled transfers;</li>
        <li>
          Money or payments being applied to amounts you owe to us or used to
          offset loss incurred by us;
        </li>
        <li>Account* limitation, suspension or termination;</li>
        <li>
          Money or payments being seized to comply with a court order, warrant
          or other legal process; and/or
        </li>
        <li>
          Money or payments you previously received being reversed (i.e., sent
          back to the sender or to the card or bank account* that was used to
          fund the payment).
        </li>

        <p>
          Among other reasons, we may take the above actions if you knowingly or
          unknowingly were a participant in a payment that was made from a
          stolen card, compromised bank account*, or compromised Aeldra
          account*, or if you were a participant in a transaction for goods and
          services between two personal accounts*.
        </p>
      </>
    ),
  },
  {
    question: 'FDIC* Notice',
    answer: (
      <p>
        Any money sent to you on Aeldra that has not yet been transferred to
        your bank account* is held by our partner bank, Blue Ridge Bank, N.A.,
        and is FDIC-Insured* for a balance up to $250,000. By using the
        Services, you authorize Aeldra to hold your funds for your benefit with
        Blue Ridge Bank, N.A.. For purposes of applicable FDIC* deposit
        insurance limitations, please note that deposits at Blue Ridge Bank,
        N.A. may not be separately insured from any other deposit accounts you
        may have with Blue Ridge Bank, N.A.
      </p>
    ),
  },
  {
    question: 'Copyright Policy',
    answer: (
      <>
        <p>
          Aeldra respects the intellectual property rights of others and expects
          you to do the same. Accordingly, we will respond to notices of alleged
          copyright infringement that comply with applicable law. If you believe
          any materials accessible on or through the Services infringe your
          copyright, you may request removal of those materials (or access to
          them) from the Services by submitting written notification to our
          Copyright Agent. Our designated Copyright Agent to receive DMCA Notice
          is: Compliance Department, Aeldra Financial Inc., 1900 S. Norfolk St,
          Ste 350, San Mateo, CA 94403.
        </p>

        <ul>
          <li>
            {' '}
            In accordance with the Digital Millennium Copyright Act of 1998, (17
            U.S.C. § 512) (“DMCA”) the text of which may be found on the U.S.
            Copyright Office website at
            http://www.copyright.gov/legislation/dmca.pdf, the written notice
            (the “DMCA Notice”) must include substantially the following:
          </li>
          <li> Your full legal name, telephone number, and email address;</li>
          <li> Physical or electronic signature;</li>
          <li>
            {' '}
            Identification of the copyrighted work you believe to have been
            infringed or, if the claim involves multiple works on the Services,
            a representative list of such works;
          </li>
          <li>
            {' '}
            Identification of the material you believe to be infringing in a
            sufficiently precise manner to allow us to locate that material;
          </li>
          <li>
            {' '}
            A statement by that you have a good faith belief that the disputed
            use has not been authorized by the copyright owner, its agent, or
            the law;
          </li>
          <li>
            {' '}
            A statement by you made under penalty of perjury, that the
            information in your notice is accurate, that you are the copyright
            owner or authorized to act on the copyright owner’s behalf; and
          </li>
          <li>
            {' '}
            Your electronic or physical signature or the electronic or physical
            signature of the person authorized to act on your behalf.
          </li>
        </ul>
        <p>
          If you fail to comply with all of the requirements of Section
          512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please
          be aware that if you knowingly materially misrepresent that material
          or activity on the Services is infringing your copyright, you may be
          held liable for damages (including costs and attorneys’ fees) under
          Section 512(f) of the DMCA.
        </p>
      </>
    ),
  },
  {
    question: 'Indemnification',
    answer: (
      <p>
        To the fullest extent permitted by law, you agree to indemnify, defend
        and hold Aeldra and all of its successors, parents, subsidiaries,
        affiliates, officers, directors, stockholders, investors, employees,
        agents, representatives and attorneys and their respective heirs,
        successors and assigns (collectively, the “Indemnified Parties”),
        harmless from and against any and all claims, liabilities, losses,
        damages, costs and expenses, including, without limitation, reasonable
        attorneys’ fees, incurred by the Indemnified Parties arising out of or
        relating to (i) your access to, use of or alleged use of the Services;
        (ii) your violation of this Agreement or any representation, warranty,
        or agreements referenced herein, or any applicable law or regulation;
        (iii) your violation of any third party right, including without
        limitation any intellectual property right, publicity, confidentiality,
        property or privacy right; or (iv) any disputes or issues between you
        and any third party. We reserve the right, at our own expense, to assume
        the exclusive defense and control of any matter otherwise subject to
        indemnification by you, and in such case, you agree to cooperate with
        our defense of such claim. You shall cooperate as fully as reasonably
        required in the defense of any such claim. Aeldra reserves the right, at
        its own expense, to assume the exclusive defense and control of any
        matter subject to indemnification by you. You agree not to settle any
        matter without the prior written consent of Aeldra.
      </p>
    ),
  },
  {
    question: 'Disclaimers - No Warranties',
    answer: (
      <p>
        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. EXCEPT AS REQUIRED
        BY LAW, AELDRA DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS REGARDING
        THE SERVICES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
        INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
        ACCURACY, QUIET ENJOYMENT, NON-INFRINGEMENT OF THIRD PARTY RIGHTS,
        FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, FITNESS FOR ANY PARTICULAR
        PURPOSE, OR ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE OR
        TRADE. SPECIFICALLY, BUT WITHOUT LIMITATION, AELDRA DOES NOT WARRANT
        THAT: (1) THE INFORMATION PROVIDED IN, OR THAT MAY BE OBTAINED FROM USE
        OF, THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE CORRECT, ACCURATE,
        UP-TO-DATE, OR RELIABLE; (2) THE SERVICES BE UNINTERRUPTED OR
        ERROR-FREE; (3) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR
        OTHER MATERIAL PURCHASED BY OR OBTAINED BY YOU THROUGH THE SERVICES WILL
        MEET YOUR EXPECTATIONS; OR (4) ANY ERRORS IN THE TECHNOLOGY WILL BE
        CORRECTED. YOU ASSUME ALL RISK FOR ALL DAMAGES, INCLUDING DAMAGE TO YOUR
        COMPUTER SYSTEM, MOBILE DEVICE OR LOSS OF DATA THAT MAY RESULT FROM YOUR
        USE OF OR ACCESS TO THE SERVICE. YOU HEREBY ACKNOWLEDGE THAT USE OF THE
        SERVICES IS AT YOUR SOLE RISK. IF YOU ARE A CALIFORNIA RESIDENT, YOU
        HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 WHICH PROVIDES: “A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
        RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
        SETTLEMENT WITH THE DEBTOR.”
      </p>
    ),
  },
  {
    question: 'Limitation of Liability',
    answer: (
      <>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER THE
          INDEMNIFIED PARTIES NOR ANY OTHER PARTY INVOLVED IN CREATING,
          PRODUCING, OPERATING OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, WHETHER BASED
          ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY
          OTHER LEGAL THEORY, WHETHER OR NOT THE INDEMNIFIED PARTIES HAVE BEEN
          INFORMED OF THE POSSIBILITY OF SUCH DAMAGE WHERE SUCH DAMAGES RESULT
          FROM: (i) YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE
          SERVICES, OR (ii) ANY PURCHASE OF A THIRD PARTY PRODUCT OR SERVICE
          BASED ON INFORMATION CONTAINED IN THE AELDRA SERVICES, INCLUDING THE
          AVAILABILITY OF A COUPON. YOU SPECIFICALLY ACKNOWLEDGE THAT THE
          INDEMNIFIED PARTIES ARE NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR
          ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF
          INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. FURTHER, THE
          INDEMNIFIED PARTIES WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD
          PARTY FOR ANY THIRD-PARTY CONTENT UPLOADED ONTO OR DOWNLOADED FROM THE
          SITE OR THROUGH THE SERVICE.
        </p>

        <p>
          {' '}
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY IN CERTAIN CIRCUMSTANCES. ACCORDINGLY, SOME OF THE ABOVE
          LIMITATIONS MAY NOT APPLY TO YOU.
        </p>

        <p>
          EACH PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
          INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THIS
          AGREEMENT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE
          BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND
          INDEPENDENT OF ALL OTHER PROVISIONS OF THIS AGREEMENT. THE LIMITATIONS
          IN THIS SECTION 21 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
          ESSENTIAL PURPOSE.
        </p>
      </>
    ),
  },
  {
    question: 'Dispute Resolution',
    answer: (
      <>
        <p>
          {' '}
          PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO
          ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US AND LIMITS THE MANNER IN
          WHICH YOU CAN SEEK RELIEF FROM US.
        </p>

        <p>
          You agree that any dispute between you and Aeldra arising out of or
          relating to this Agreement or the Services (collectively, “Disputes”)
          will be governed by the arbitration procedure outlined below.
        </p>
        <p>
          Governing Law. Except as otherwise required by applicable law, the
          Agreement and the resolution of any Disputes shall be governed by and
          construed in accordance with the laws of the State of California
          without regard to its conflict of laws principles. These laws will
          apply no matter where in the world you live, but if you live outside
          of the United States, you may be entitled to the protection of the
          mandatory consumer protection provisions of your local consumer
          protection law.
        </p>
        <p>
          Informal Dispute Resolution. We want to address your concerns without
          needing a formal legal case. Before filing a claim against Aeldra, you
          agree to try to resolve the Dispute informally by contacting
          concierge@aeldra.com. We will try to resolve the Dispute informally by
          contacting you through email. If a dispute is not resolved within 15
          days after submission, you or Aeldra may bring a formal proceeding.
        </p>
        <p>
          We Both Agree To Arbitrate. You and Aeldra agree to resolve any
          Disputes through final and binding arbitration, except as set forth
          under Exceptions to Agreement to Arbitrate below.
        </p>
        <p>
          Opt-out of Agreement to Arbitrate. You can decline this agreement to
          arbitrate by contacting [insert email] within 30 days of first
          accepting this Agreement and stating that you (include your first and
          last name) decline this arbitration agreement.
        </p>
        <p>
          Arbitration Procedures. The American Arbitration Association (AAA)
          will administer the arbitration under its Commercial Arbitration Rules
          and the Supplementary Procedures for Consumer Related Disputes then in
          effect. Arbitration will be handled by a sole arbitrator in accordance
          with those rules. The arbitration will be held in the United States
          county where you live or work, San Francisco, California, or any other
          location we agree to.
        </p>
        <p>
          Arbitration Fees. The AAA rules will govern payment of all arbitration
          fees. If the value of your claim does not exceed $10,000, Aeldra will
          pay for the reasonable filing, administrative, and arbitrator fees
          associated with the arbitration, unless the arbitrator finds that
          either the substance of your claim or the relief sought was frivolous
          or brought for an improper purpose.
        </p>
        <p>
          Exceptions to Agreement to Arbitrate. Either you or Aeldra may assert
          claims, if they qualify, in small claims court in San Francisco,
          California, or any United States county where you live or work. Either
          party may bring a lawsuit solely for injunctive relief to stop
          unauthorized use or abuse of the Services, or infringement of
          intellectual property rights (for example, trademark, trade secret,
          copyright or patent rights) without first engaging in arbitration or
          the informal dispute-resolution process described above.
        </p>
        <p>
          No Class Actions. You may only resolve Disputes with Aeldra on an
          individual basis, and may not bring a claim as a plaintiff or a class
          member in a class, consolidated, or representative action. Class
          arbitrations, class actions, private attorney general actions, and
          consolidation with other arbitrations are not allowed under our
          agreement.
        </p>
        <p>
          Judicial Forum for Disputes. Except as otherwise required by
          applicable law, in the event that the agreement to arbitrate is found
          not to apply to you or your claim, you and Aeldra agree that any
          judicial proceeding (other than small claims actions) will be brought
          in the federal or state courts of San Francisco County, California.
          Both you and Aeldra consent to venue and personal jurisdiction there.
          We both agree to waive our right to a jury trial.
        </p>
        <p>
          Limitation on Claims. Regardless of any statute or law to the
          contrary, any claim or cause of action arising out of or related to
          this Agreement or the Services must be filed within one (1) year after
          such claim or cause of action arose, or else that claim or cause of
          action will be barred forever.
        </p>
      </>
    ),
  },
  {
    question: 'General',
    answer: (
      <>
        <p>
          This Agreement, together with the Privacy Policy and any other
          agreements expressly incorporated by reference herein, constitute the
          entire and exclusive understanding and agreement between you and
          Aeldra regarding your use of and access to the Service, and except as
          expressly permitted above may only be amended by a written agreement
          signed by authorized representatives of the parties. You may not
          assign or transfer this Agreement or your rights hereunder, in whole
          or in part, by operation of law or otherwise, without our prior
          written consent. We may assign this Agreement or any of our rights or
          obligations under this Agreement at any time without notice. The
          failure to require performance of any provision shall not affect our
          right to require performance at any time thereafter, nor shall a
          waiver of any breach or default of this Agreement constitute a waiver
          of any subsequent breach or default or a waiver of the provision
          itself. Use of paragraph headers in this Agreement is for convenience
          only and shall not have any impact on the interpretation of particular
          provisions. In the event that any part of this Agreement is held to be
          invalid or unenforceable, the unenforceable part shall be given effect
          to the greatest extent possible and the remaining parts will remain in
          full force and effect.
        </p>
        <p>
          Aeldra&apos;s Product Agreement can be found on our website at this{' '}
          <a href="/product-agreement">link</a>.
        </p>
      </>
    ),
  },
  {
    question: 'Contact Information',
    answer: (
      <p>
        If you have any questions regarding Aeldra, the Services, or this
        Agreement please email us at{' '}
        <a href="mailto:concierge@aeldra.com">concierge@aeldra.com</a>
      </p>
    ),
  },
].map(({ question, answer }, id) => ({
  question,
  answer,
  id,
}));

function Terms() {
  return (
    <Layout>
      <SEO
        title="Terms and Conditions | Aeldra"
        description="In order to use the Services, you must: (a) accept and agree to these Terms and our Privacy Policy (b) register with us on the Website or the Application; (c) be a Resident of the United States or a Citizen and Resident of India, (d) be at least 18 years old; (e) provide all information requested by us, such as your name, email address, mobile device number, online credentials for your Bank Account*, and such other information as we may request from time to time (collectively, “User Information”)"
      />

      <div className="no-hero-layout">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-2">
              <h1>Terms and Conditions</h1>

              <p>As of November 1, 2020</p>

              <div className="terms-content">
                <Accordion data={TermsFaq} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Terms;
